import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FREE_SPINS_LIST, FREE_SPINS_QUEUE, USER_PRIZES_PENDING_REWARDS } from '../http/base-http.service';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HOST_NAME } from '../../global.tokens';
import { concatMap, map } from 'rxjs/operators';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { LoginStatusService } from '../auth/login/login-status.service';
import { PendingReward } from './prizes.models';


@Injectable({
  providedIn: 'root'
})
export class PendingRewardsService extends BaseFirebaseService {


  constructor(
    afAuth: AngularFireAuth,
    http: HttpClient,
    afs: AngularFirestore,
    private loginStatusService: LoginStatusService,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, afAuth, afs, hostName);
  }

  public getPendingRewards() {
    return this.loginStatusService.getIfUserLogged().pipe(
      concatMap(user => {
        return this.afs.collection('backend-data-users').doc(user.username).collection<PendingReward>('pending-rewards', ref => {
          let query: CollectionReference | Query = ref;
          query = query.orderBy('createdAt', "desc")
          return query
        }).snapshotChanges()

      }),
      map(resp => {
        const respArray = []
        resp.forEach((doc) => {
          const docData = doc.payload.doc.data();
          console.log('id' + docData);
          respArray.push({ id: doc.payload.doc.id, ...docData })
        });
        return respArray;
      })
    )
  }

  public applyReward(prizeId) {
    return this.post(USER_PRIZES_PENDING_REWARDS, { prizeId: prizeId })
  }
}
