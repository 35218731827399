import { Component } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { DetectDeviceService } from '../../../services/utils/detect-device.service';

@Component({
  selector: 'app-main-client-area',
  templateUrl: './main-client-area.component.html',
  styleUrls: ['./main-client-area.component.scss']
})
export class MainClientAreaComponent extends BasePageComponent {

  constructor(
    protected detectDesktopService: DetectDeviceService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isDesktop = this.detectDesktopService.isDesktop();
  }
}
