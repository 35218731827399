export interface BalanceResponse {
    currency: string,
    points: number,
    bonus_multiplier?: string,
    bonus_part: number,
    real_part: number,
    bonus_balance: number,
    balance: string,
    bonus_expire?: string,
    bonus_code?: string,
    non_sticky_bonus?: boolean
}

export interface BalanceInAppInfo {
    currency?: string,
    balance?: number,
    bonus_balance?: number,
    non_sticky_bonus?: boolean
}

export interface ActivePrizes {
    code: string,
    end_date: string,
    enqueueing_date: string,
    freespins: number,
    games: {
        id: number,
        name: string
    }[],
    kind: string,
    kind_display: string,
    name: string,
    note?: string,
    start_date: string,
    status: string,
    status_display: string,
    valid_until_date: string,
}

export interface BonusQueueItem {
    id: number,
    enqueueing_date: string,
    bonus_ref: string,
    status: string,
    bonus_amount: number,
    bonus_amount_in_wallet_currency: number,
    bonus_currency: string,
    days_valid: string,
}
export enum BonusStatus {
    ACTIVE = 'A',
    CANCELED = 'C',
    EXPORED = 'E'
}

export interface BonusListItem {
    bonus_ref?: string,
    grant_date: string,
    expiry_date: string,
    bonus_link: string,
    status: BonusStatus,
    raw_status: string,
    granted_amount: number,
    granted_amount_in_wallet_currency: number,
    turnover_sum: number,
    redemption_amount: number,
    redemption_amount_in_wallet_currency: number,
    bonus_name?: string,
    active_bonus_balance?: BonusItemBalance
}

export interface BonusItemBalance {
    real: number,
    bonus: number,
    currency: string
}

export interface BonusQueueReorderRequest {
    top: number,
    item: number
}
