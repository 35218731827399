import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { take } from 'rxjs/operators';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SMALL_DIALOG_CONFIG } from 'src/app/components/dialog/dialog.config';
import { PrizeBox } from 'src/app/services/client-area/client-area.models';
import { BonusLotteryService } from 'src/app/services/profile/bonuslottery.service';
import { UserBonusLottery } from 'src/app/services/profile/profile.models';
import { LotteryState } from '../prize-boxes.component';
import { PrizeInfoDialogComponent } from '../prize-info-dialog/prize-info-dialog.component';

@Component({
  selector: 'app-lottery-prize-box',
  templateUrl: './lottery-prize-box.component.html',
  styleUrls: ['./lottery-prize-box.component.scss']
})
export class LotteryPrizeBoxComponent extends BasePageComponent {

  constructor(
    public deviceDetect: DeviceDetectorService,
    private bonusLotteryService:BonusLotteryService,
    public infoDialog: MatDialog,
  ) { super() }

  @Input() prizeBoxLottery:UserBonusLottery;
  lotteryState: LotteryState = LotteryState.EMPTY;
  lotteryStateFinished:LotteryState = LotteryState.FINISHED;
  leftToOpen: number;

  ngOnInit(): void {
    console.log(this.prizeBoxLottery)
    this.leftToOpen = this.prizeBoxLottery.leftToOpen;
  }

  openPrizeBox(event, prizeBox: PrizeBox) {
    if (prizeBox.isOpen) return;
    if (this.leftToOpen>0){
      this.leftToOpen-=1;
      this.bonusLotteryService.openLotteryBox(prizeBox).pipe(take(1)).subscribe((prize) => {
        prizeBox.prize = prize;
        prizeBox.isOpen = true;
        if (this.leftToOpen===0) this.lotteryState=LotteryState.FINISHED;
       },(error)=>{
       })
    }else{

      // this.showBoxes = false;
      // this.getPrizeBoxes();
    }
  }

  openInfoDialog(): void {
    const dialogRef = this.infoDialog.open(PrizeInfoDialogComponent, {
      ...SMALL_DIALOG_CONFIG,
    });

  }

}
