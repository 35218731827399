<div fxLayoutAlign="space-between center">
  <div class="category-title-h1" i18n>Promo boxes</div>
  <div class="left-info"><span i18n>Left</span> {{leftToOpen}}</div>
</div>
<div fxLayout="row wrap" fxLayoutAlign="start center">
    <div fxFlex="33" fxFlex.gt-xs="25" class="prize-card" [ngClass]="{'desktop':deviceDetect.isDesktop()}" fxLayoutGap="2px" *ngFor="let prizeBox of prizeBoxLottery.prizeBoxes; let first = first" fxLayoutAlign="center center">
        <div class="prize-box rectangle" (click)="openPrizeBox($event,prizeBox)" [ngClass]="{'open': prizeBox.isOpen, 'desktop':deviceDetect.isDesktop()}"
        fxLayoutAlign="center center">
        <ng-container *ngIf="lotteryState !== lotteryStateFinished"> 
          <img *ngIf="!prizeBox.isOpen" [ngClass]="{'shake-image': first}" src="assets/images/chess-closed.png"
            />
        </ng-container>
       
        <div class="open-label" (click)="openInfoDialog()" *ngIf="prizeBox.isOpen">
          {{prizeBox.prize?.displayName}}
        </div>
       
      </div>
    </div>
</div>