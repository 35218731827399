import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { bonusQueueTrx, clientAreaTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { TranslationConfig } from 'src/app/utils/translate-config';

@Component({
  selector: 'app-prize-info-dialog',
  templateUrl: './prize-info-dialog.component.html',
  styleUrls: ['./prize-info-dialog.component.scss']
})
export class PrizeInfoDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PrizeInfoDialogComponent>,
    public detectDevice: DetectDeviceService,
    private translationConfig: TranslationConfig
  ) { }

  public bonusQueueUrl = ["", this.translationConfig.getTranslation(clientAreaTrx), this.translationConfig.getTranslation(bonusQueueTrx)];

  ngOnInit(): void {
  }


  closeDialog(): void {
    this.dialogRef.close();
  }
}
